<template>
  <div class="market-facilitator-list-wrap" v-if="listData.list && listData.list.length">
    <div class="market-facilitator-list" v-for="(item, index) in listData.list" :key="index">
      <div class="market-facilitator-view">
        <img :src="item.companyLogo" alt="" style="cursor: pointer;" @click="go_detail(item)">
      </div>
      <div class="market-facilitator-title">
        <a class="title" id="title" style="cursor: pointer;" @click="go_detail(item)">{{item.companyName}}</a>
      </div>
      <div class="market-facilitator-info">
          {{item.companyDesc}}
      </div>
      <div class="market-facilitator-other">
          行业经验：{{item.industryExperience < 0 ? '若干月' : item.industryExperience+'年以上'}}
      </div>
    </div>
  </div>
</template>

<script>
import { jump } from '../../util/jumpPage'

export default {
  name: 'ProviderMainlistV2',
  props: {
    listData: {
      type: Object,
      default: {
        total: 0,
        list: []
      }
    }
  },
  methods: {
    go_detail(item) {
      let url = item.cooperationType == 2 ? 'providerdetail' : 'partnerdetail'
      window.location.href = `/market/${url}/${item.id}`
    },
  }
}
</script>
